<template>
  <div id="inspire">
    <v-fab-transition id="world-btn">
      <v-btn
          :v-show="true"
          @click="updateCurrentComponent('world')"
          color="white"
          :style="{ left: '50%', transform: 'translateX(-50%)' , top: '-20px'}"
          dark
          absolute
          top
          fab
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
        >
          <path d="M0 0h24v24H0z" fill="none"/>
          <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"
          />
        </svg>
      </v-btn>
      <v-btn class="white--text" color="teal"></v-btn>
    </v-fab-transition>

    <v-fab-transition>
      <v-btn
          fab
          fixed
          top
          style="margin: 20px; top: 50px"
          @click="drawer=!drawer"
      >
        <v-icon>mdi-reorder-horizontal</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer
        v-model="drawer"
        app
        absolute
        temporary
    >
      <v-sheet
          color="grey lighten-4"
          class="pa-4"
      >
        <v-avatar
            class="mb-4"
            color="grey darken-1"
            size="64"
        ></v-avatar>

        <div>john@vuetifyjs.com</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
            v-for="[icon, text, id] in links"
            :key="icon"
            link
            @click="currentLink=id"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <Profile v-if="currentLink === 'profile'"></Profile>
    <Admin v-else-if="currentLink === 'admin'"></Admin>

    <!--    <v-main>
          <v-container
              class="py-8 px-6"
              fluid
          >
            <v-row>
              <v-col
                  v-for="card in cards"
                  :key="card"
                  cols="12"
              >
                <v-card>
                  <v-subheader>{{ card }}</v-subheader>

                  <v-list two-line>
                    <template v-for="n in 6">
                      <v-list-item

                          :key="n"
                      >
                        <v-list-item-avatar color="grey darken-1">
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>Message {{ n }}</v-list-item-title>

                          <v-list-item-subtitle>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil repellendus distinctio similique
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider
                          v-if="n !== 6"
                          :key="`divider-${n}`"
                          inset
                      ></v-divider>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <div>Hello</div>
        </v-main>-->
  </div>
</template>

<script>
import Profile from "@/components/Profile";
import Admin from "@/components/Admin";
import {mapMutations} from "vuex";

export default {

  methods:{
    ...mapMutations([
        'updateCurrentComponent'
    ])
  },
  components: {
    Profile,
    Admin
  },
  data: () => ({
    cards: ['Today', 'Yesterday'],
    drawer: null,
    links: [
      ['mdi-account', 'Profile', 'profile'],
      ['mdi-wrench', 'Admin', 'admin'],
    ],
    currentLink: 'profile',
  }),
}
</script>