/*
	Author: Alexander Stanchula
	Desc  :  Root View Layer view which contains all in game componets and checks for valid access token
*/

<template>
  <div>
    <WorldScreen v-if="CurrentComponent === 'world'" @showWorld="show_world" />
    <Settings v-else-if="CurrentComponent === 'settings'" @showWorld="show_world" />
    <QuestionScreen v-else @showWorld="show_world" />

    <v-overlay v-if="isAuth == false">
      <v-alert prominent type="error" >
        <v-row align="center">
          <v-col >
            Ihre Session ist leider abgelaufen. Bitte melden Sie sich neu an um
            fortzufahren.
          </v-col>
          <v-col class="shrink">
            <v-btn @click="logout" style="margin:10px">Zum LogIn</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
  </div>
</template>

<script>
import WorldScreen from "./../components/World.vue"
import axios from "axios";
import QuestionScreen from "./../components/Questions.vue";
import {mapActions, mapState } from 'vuex';
import conf from "./../../package.json"
import Settings from "@/components/Settings";


//  import axios from 'axios'
//  import conf from "./../../package.json"

//  const host = conf.host

export default {
  name: "InGame",
  directives: {},
  components: {
    Settings,
    WorldScreen,
    QuestionScreen,
  },
  data() {
    return {
      isAuth: null,
      world: true,
      host: conf.host,
    };
  },
  methods: {
    checkAuth() {
      console.log("CHECK");
    },
    ...mapActions([
        'doLogout',
        'setCurrentComponent',
        'fetchCurrentComponent'
      ]),
    logout(){
      this.doLogout()
      this.$router.push("/")
    },
    show_world(val){
      console.log(val)
      if(val){
        this.setCurrentComponent('world')
      } else{
        this.setCurrentComponent('questions')
      }
      this.world = val
    }
  },
  created() {
    this.$emit("is_home");
    this.fetchCurrentComponent()
    var token = localStorage.getItem("accessToken");
    console.log(`${this.host}/api/auth/verify/${token}`);
    console.log(this.isAuth);
    axios
      .get(`${this.host}/api/auth/verify/${token}`)
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.isAuth = true;
        } else this.isAuth = false;
    console.log(this.isAuth);

      })
      .catch((error) => {
        console.log(error);
        this.isAuth = false;
    console.log(this.isAuth);

      });
  },
  computed: {
      ...mapState([
        'loggingIn',
        'loginError',
        'accessToken',
        'CurrentComponent',

      ])
    },
};
</script>

<style scoped>
div {
  margin: 0px !important ;
  padding: 0px !important;
  width: 100%;
  height: 100%;
}
html {
  overflow-y: hidden;
}

#app {
}

.section {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
</style>


