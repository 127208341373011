/*
	Author: Alexander Stanchula
	Desc  : getting and setting the app state
*/

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import conf from './../package.json'

const host = conf.host

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: null,
    loggingIn: false,
    loginError: null,
    signUp: null,
    signingUp: false,
    PName: null,
    PID: null,
    CurrentComponent: 'world',
    region: '',
    rId: 0,
    selectedClass: ''
  },
  mutations: {
    loginStart: state => state.loggingIn = true,
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    signUpStart: state => state.signingUp = true,
    signUpStop: (state) => {
      state.signingUp = false;
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    },
    updateSignUp:(state, signUp) => {
        state.signUp = signUp
    },
    updatePName: (state, username) => {
        state.PName = username
        localStorage.setItem('PName',username)
    },

    updatePID: (state, PID) => {
      state.PID = PID
      localStorage.setItem('PID',PID)
  },
    updateCurrentComponent: (state, CurrentComponent) => {
      state.CurrentComponent = CurrentComponent
      localStorage.setItem('CurrentComponent', CurrentComponent)
    },
    updateRegion: (state, region) => {
      state.region = region;
      localStorage.setItem('region', region);
    },
    updateRegionId: (state, rId) => {
      state.rId = rId;
      localStorage.setItem('rId', rId);
    },
    updateSelectedClass: (state, selectedClass) => {
      state.selectedAnswer = selectedClass;
      localStorage.setItem('selectedClass', selectedClass)
    }
  },
  actions: {
    doLogin({ commit }, loginData) {
      commit('loginStart');
        console.log(host, 'host')
        axios.get(`https://foodrally-api.onrender.com/api/auth/signin/${loginData.username}/${loginData.password}`)
      .then(response => {
        localStorage.setItem('accessToken', response.data.accessToken);
        console.log(response.data)
        commit('loginStop', null);
        commit('updateAccessToken', response.data.accessToken);
        commit('updatePName', loginData.username)
        commit('updatePID', response.data.id)
        commit('updateCurrentComponent',"world")

        
      })
      .catch(error => {
          console.log(error)
        commit('loginStop', error);
        commit('updateAccessToken', null);
      })
    },
    async doSignUp({commit}, signUpData){
      commit('signUpStart');

        axios.get(`${host}/api/auth/signup/${signUpData.username}/${signUpData.password}`)
      .then(response => {
          console.log(response)
          
          if(response.data == true){
            //   this.doLogin(signUpData)
            commit('updateSignUp', true)
            commit('signUpStop');

            console.log('STORE')
          } else{
              commit('updateSignUp',false)
              commit('signUpStop');
          }
      })
      .catch(error => {
          console.log(error)
          commit('updateSignUp',false)
          commit('signUpStop');

      })
    },
    doLogout({commit}){
        localStorage.setItem('accessToken', null);
        commit('updateAccessToken', null)
        commit('updatePName',null)
        commit('updatePID',null)
        commit('updateCurrentComponent',null)

    },
    setCurrentComponent({commit},CurrentComponent) {
      commit('updateCurrentComponent', CurrentComponent)
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', localStorage.getItem('accessToken'));
    },
    fetchCurrentComponent({ commit }) {
      commit('updateCurrentComponent', localStorage.getItem('CurrentComponent'));
    },
  }
})