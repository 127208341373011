/*
Author: Sara Ogris
Desc  :  VueJS Quiz Component - list of questions and card overlay with the selected question
and ability to set and check the answer
*/

<template>
  <v-card light style="margin: 10px">
    <!-- World Button -->
    <v-fab-transition id="world-btn">
      <v-btn
          :v-show="true"
          @click="world"
          color="white"
          :style="{ left: '50%', transform: 'translateX(-50%)' }"
          dark
          absolute
          top
          fab
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
        >
          <path d="M0 0h24v24H0z" fill="none"/>
          <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"
          />
        </svg>
      </v-btn>
      <v-btn class="white--text" color="teal"></v-btn>
    </v-fab-transition>

    <template>
      <!-- Quiz-Overlay -->
      <v-overlay :absolute="absolute" :value="focusQuestion">
        <!-- Auflistung der Fragen -->
        <v-card light id="questionCard">
          <div>
            <!-- Titel der Frage  -->
            <v-card-title id="top">
              <h1 class="white--text">{{ item.qTitle }}</h1>
            </v-card-title>
            <!-- Frage -->
            <v-card-text id="cardText">
              <h2>{{ item.qText }}</h2>
            </v-card-text>
          </div>
          <!-- Antwortmöglichkeiten  -->
          <div v-for="(answer, index) in answers" :key="answer">
            <v-card-text>
              <v-container id="cardTextBackground">
                <v-row>
                  <v-row style="margin: 0px; width: 100%">
                    <v-btn
                        :disabled="
                      item.score == 1 || item.score == 0 || item.isDisabled
                    "
                        :class="answerClass(index)"
                        @click="

                      setAnswer(answer, index);
                    "
                    >
                      <!-- erste Antwortmöglichkeit -->
                      <h3>{{ index + 1 }}. {{ answer.atext }}</h3>
                      <span
                          style="
                        white-space: normal;
                        word-break: break-word;
                        max-width: 700px;
                        max-height: 70px;
                      "
                      >
                    </span>
                    </v-btn>
                  </v-row>

                  <!--                <v-row style="margin: 0px; width: 100%">
                                    <v-btn
                                      :disabled="
                                        item.score == 1 || item.score == 0 || item.isDisabled
                                      "
                                      :class="chip2_class"
                                      @click="
                                        chip2_class = 'answerSelected';
                                        chip1_class = 'answerNormal';
                                        chip3_class = 'answerNormal';
                                        setAnswer(answers[1], 'chip2_class');
                                      "
                                    >
                                    &lt;!&ndash; zweite Antwortmöglichkeit &ndash;&gt;
                                      <h3>2. {{ answers[1] }}</h3>
                                    </v-btn>
                                  </v-row>
                                  <v-row style="margin: 0px; width: 100%">
                                    <v-btn
                                      :disabled="
                                        item.score == 1 || item.score == 0 || item.isDisabled
                                      "
                                      :class="chip3_class"
                                      @click="
                                        chip3_class = 'answerSelected';
                                        chip1_class = 'answerNormal';
                                        chip2_class = 'answerNormal';
                                        setAnswer(answers[2], 'chip3_class');
                                      "
                                    >
                                    &lt;!&ndash; dritte Antwortmöglichkeit &ndash;&gt;
                                      <h3>3. {{ answers[2] }}</h3>
                                    </v-btn>
                                  </v-row>-->
                </v-row>
              </v-container>
            </v-card-text>
          </div>
          <!-- Zusatzinformation einblenden -->
          <v-card-text
              v-show="show_information || item.score == 1"
              id="infoCard"
          >
            <v-container id="infoText">
              <h2 style="color: rgb(68, 118, 160)">Wusstest du schon</h2>
              {{ item.info }}
            </v-container>
          </v-card-text>

          <!-- Zurück Button -->
          <v-container style="background-color: #f2f2f2">
            <v-row>
              <v-card-actions>
                <v-btn
                    text
                    id="backButton"
                    @click="
                    focusQuestion = !focusQuestion;
                    chip3_class = 'answerNormal';
                    chip1_class = 'answerNormal';
                    chip2_class = 'answerNormal';
                    refreshData();
                  "
                    v-on:click="show_information = false"
                >
                  <h4>Zurück!</h4>
                </v-btn>
              </v-card-actions>
              <v-spacer></v-spacer>
              <!-- Bestätigungs Button -->
              <v-btn
                  :disabled="
                  item.score == 1 || item.score == 0 || item.isDisabled
                "
                  :loading="checkAnswerLoader"
                  id="submitButton"
                  @click="checkAnswer(selectedAnswer, item)"
              >
                <h4>Antworten</h4>
              </v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-overlay>
    </template>
    <!-- Frage Titel der Region -->
    <div id="titleBackground">
      <h1 id="questionRegion">Fragen - {{ region }}</h1>
    </div>
    <!-- Liste der Fragen mit Titel der Frage und darunter die Frage -->
    <v-list two-line>
      <v-list-item-group active-class="black--text">
        <div>
          <template v-for="(item, index) in items">
            <v-list-item
                id="border"
                :selectable="false"
                :key="item.title"
                :class="item.class"
                @click="showQuestion(item)"
            >
              <template>
                <v-list-item-content id="listItemContent">
                  <!-- Titel der Frage -->
                  <v-list-item-title
                      id="listItemTitle"
                      v-text="item.qTitle"
                  ></v-list-item-title>
                  <!-- Frage -->
                  <v-list-item-subtitle
                      id="listItemSubtitle"
                      v-text="item.qText"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <!-- Kreis, wird durch den Countdown immer kleiner -->
                <v-progress-circular
                    v-show="item.wrong"
                    id="progressCircle"
                    :rotate="360"
                    :size="120"
                    :width="15"
                    :value="item.progressBar"
                >
                  {{ item.timeLeft }}
                </v-progress-circular>
                <!-- Icons auf der rechten Seite -->
                <v-list-item-action>
                  <!-- Falsche Antwort, Icon X-Symbol und Rot eingefärbt -->
                  <v-icon
                      x-large
                      v-if="item.class == 'wrongQuestion'"
                      color="red darken-3"
                  >
                    mdi-close-circle
                  </v-icon>

                  <!-- Richtige Antwort, Icon mit Check Symbol und Grün eingefärbt -->
                  <v-icon
                      x-large
                      v-else-if="item.class == 'correctQuestion'"
                      color="green darken-3"
                  >
                    mdi-check-circle
                  </v-icon>
                  <!-- Nicht beantwortet, Icon Fragezeichen-Symbol und Orange eingefärbt -->
                  <v-icon
                      x-large
                      v-else-if="item.class == 'openQuestion'"
                      color="orange darken-3"
                  >
                    mdi-help-circle
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
            <div
                id="divider"
                v-if="index < items.length - 1"
                :key="index"
            ></div>
          </template>
        </div>
      </v-list-item-group>
    </v-list>
    <!-- Animation, wenn alle Fragen richtig beantwortet wurden und Rezept freigeschalten wurde -->
    <v-overlay v-show="recipe_unlocked">
      <v-snackbar
          v-model="recipe_unlocked"
          centered
          rounded
          multiline
          timeout="-1"
          color="success"
      >
        <v-layout align-center pr-4>
          <v-icon class="pr-3" dark large>mdi-check-circle-outline</v-icon>
          <v-layout column>
            <div>
              <strong id="listItemTitle">HURRA!</strong>
            </div>
            <div id="listItemSubtitle">
              Herzlichen Glückwunsch! Du hast ALLE Fragen Richtig beantwortet.
              Dir wurde ein Rezept zu dieser Region freigeschaltet. Dass findest
              du in der Welt-Ansicht in deinem Profil.
            </div>
          </v-layout>
        </v-layout>

        <template v-slot:action="{ attrs }">
          <v-btn color="black" v-bind="attrs" @click="stopConfetti">
            Zurück
          </v-btn>
        </template>
      </v-snackbar>
    </v-overlay>
  </v-card>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";

Vue.use(VueConfetti);
import axios from "axios";
import {mapMutations, mapState} from "vuex";

const base_url = "https://foodrally-api.onrender.com";

export default {
  computed: {
    ...mapState([
      'region',
      'selectedClass',
    ])
  },
  methods: {
    ...mapMutations([
      'updateCurrentComponent',
      'updateSelectedClass'
    ]),
    startConfetti() {
      this.$confetti.start();
    },
    stopConfetti() {
      this.$confetti.stop();
      this.recipe_unlocked = false
    },
    startCountDown() {
      this.items.forEach((item) => {
        if (item.timelock != null) {
          item.timelock = new Date(item.timelock);
          console.log(item.timelock);
          item.timelock = Date.now() - item.timelock;
          this.countDownTimer(item);
        }
      });
    },
    async countDownTimer(item) {
      if (3600000 * 1 - item.timelock > 0) {
        setTimeout(() => {
          item.timelock += 1000;
          var d = new Date(3600000 * 1 - item.timelock);
          item.timeLeft = `${d.getHours() - 1}:${d
              .getMinutes()
              .toString()
              .padStart(2, "0")}:${d.getSeconds().toString().padStart(2, "0")}`;

          item.progressBar =
              ((3600000 * 1 - item.timelock) * 100) / (3600000 * 1);
          // console.log(item.progressBar);
          // console.log(this.progress_val)
          this.countDownTimer(item);
        }, 1000);
      } else {
        console.log("ENDE");
        this.endCountDown(item);
      }
    },
    endCountDown(item) {
      axios
          .get(
              `${base_url}/api/question/updateCountDown/${
                  item.QID
              }/${localStorage.getItem("PID")}/NULL`
          )
          .then(async (response) => {
            console.log(response);
            item.timelock = null;
            item.score = null;
            item.isDisabled = false;
            item.class = "openQuestion";
            item.wrong = false;
          });
    },
    convertToHoursAndMinute(x) {
      var hours = x / 3600000;
      hours = parseInt(hours);

      var minutes = parseInt((x - hours * 3600000) / 60000);

      return `${hours}:${minutes}`;
    },

    showQuestion: function (item) {
      this.focusQuestion = true;
      this.item = item;
      console.log(item)
      this.answers = item.Answers;
    },
    setAnswer: function (answer, index) {
      this.selectedAnswer = answer;
      console.log(answer)
      console.log(index)
      switch (index) {
        case 0:
          this.chip1_class = "answerSelected"
          this.chip2_class = "answerNormal"
          this.chip3_class = "answerNormal"
          this.selected_class = "chip1_class"
          console.log(`set selected_class to: ${this.selected_class}`)
          break;
        case 1:
          this.chip2_class = "answerSelected"
          this.chip1_class = "answerNormal"
          this.chip3_class = "answerNormal"
          this.selected_class = "chip2_class"

          break;
        case 2:
          this.chip3_class = "answerSelected"
          this.chip1_class = "answerNormal"
          this.chip2_class = "answerNormal"
          this.selected_class = "chip3_class"

          break;


      }
    },
    checkAnswer(answer, item) {
      console.log("ANSWER")
      console.log(answer)
      this.checkAnswerLoader = true;
      if (answer.isRight) {
        console.log(this.selected_class)
        // this.show_information = true;
        switch (this.selected_class) {
          case "chip1_class":
            console.log("RIGHTQUESTION")
            this.chip1_class = "correctAnswer";
            break;
          case "chip2_class":
            this.chip2_class = "correctAnswer";
            break;
          case "chip3_class":
            this.chip3_class = "correctAnswer";
            break;
        }
        // if (response.data[1] == "recipe_unlocked") {
        //   this.startConfetti()
        //   this.recipe_unlocked = true
        // }
      } else if (answer.IsRight == 0) {
        switch (this.selected_class) {
          case "chip1_class":
            this.chip1_class = "wrongAnswer";
            break;
          case "chip2_class":
            this.chip2_class = "wrongAnswer";
            break;
          case "chip3_class":
            this.chip3_class = "wrongAnswer";
            break;
        }
      }

      item.isDisabled = true;
      this.checkAnswerLoader = false;

    },
    world: function () {
      this.updateCurrentComponent('world')
      this.$emit("showWorld", true);
    },
    refreshData() {
      this.created()
    },
    answerClass(index) {
      switch (index) {

        case 0:
          return this.chip1_class;
        case 1:
          return this.chip2_class;
        default:
          return this.chip3_class;
      }
    }
  },
  created() {
    this.items = [];
    this.userScore = [];
    axios
        .get(
            `${base_url}/api/region/questions/${localStorage.getItem("rId")}`
        )
        .then(async (response) => {
          this.items = response.data;
          this.startCountDown();
        });

    axios.get(
        `${base_url}/api/score/${localStorage.getItem("PID")}`
    ).then(async (response) => {
      this.userScore = response.data;
    })
  },
  data: () => ({
    countDown: new Date("12.12.2021 14:00") - Date.now(),
    timestamp: new Date("12.12.2021 12:00"),
    timeLeft: null,
    progress_val: 0,
    toggleDefault: null,
    chip1_class: "answerNormal",
    chip2_class: "answerNormal",
    chip3_class: "answerNormal",
    answers_0: "answers",
    absolute: false,
    show_information: false,
    item: "",
    overlay: false,
    answers: ["", "", ""],
    selectedAnswer: "",
    selectedRows: [],
    checkAnswerLoader: false,
    selected_class: "",
    recipe_unlocked: false,
    userScore: [],
    focusQuestion: false,

    items: [
      {
        wrong: true,
        title: "ZUCKER",
        class: "wrongQuestion",
        question: `Welche süße Backware mögen Norweger und Schweden ganz besonders?`,
        answers: [],
        info: "",
      },
    ],
  }),
};
</script>

<style scoped src="../Styles/Question.css">
</style>