/*
Author: Alexander Stanchula
Desc  :  VueJS Login Component
*/

<template>
  <v-layout align-center justify-center height="800px">
    <v-flex min-width=150px>
      <v-card class="elevation-12">
        <v-toolbar dark color="green">
          <v-toolbar-title>Login</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
                prepend-icon="mdi-account"
                name="login"
                label="Nutzername"
                id="login"
                type="text"
                v-model="username"
            ></v-text-field>
            <v-text-field
                prepend-icon="mdi-shield"
                name="password"
                label="Passwort"
                id="password"
                type="password"
                v-model="password"
            ></v-text-field>
            <div id="error_msg" v-if="loginError" style="color:red">
              Falscher Nutzername und/oder falsches Passwort!
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="blue" @click="open">
            Hier zur Registrierung!
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              :loading="loading"
              :disabled="false"
              dark color="blue" @click="loginSubmit">Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
      loading: null,
      loginErr: this.loggingIn
    }
  },
  computed: {
    ...mapState([
      'loggingIn',
      'loginError',
      'accessToken'
    ])
  },
  methods: {
    ...mapActions([
      'doLogin'
    ]),
    loginSubmit() {
      this.doLogin({
        username: this.username,
        password: this.password
      });
      console.log(this.loggingIn)
      this.loading = true
      this.logInLoop()

    },
    logInLoop() {
      if (this.loggingIn)
        setTimeout(() => (this.logInLoop()), 200)
      else this.logIn()
    },
    logIn() {
      this.loading = false

      if (this.accessToken) {
        this.$router.push('/app')
      }
    },
    open: function () {
      this.$emit('openRegister')
    },
    props: ["openRegister"]
  },
}

</script>