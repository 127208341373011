<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-select
              :items="continentItems"
              :value="continentItems[0]"
              label="Select a continent..."
              outlined
              style="margin: 10px"
          ></v-select>


        </v-col>
        <v-col>
          <v-select
              :items="regions"
              item-text="rTitle"
              item-value="rId"
              v-model="region"
              label="Select a region..."
              outlined
              style="margin: 10px"

          ></v-select>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>

    <v-tabs centered>
      <v-tab @click="currentTab = 'question'">Questions</v-tab>
      <v-tab @click="currentTab = 'recipe'">Recipe</v-tab>
      <v-tab @click="currentTab = 'region'">Region</v-tab>
    </v-tabs>


    <QuestionAdmin v-if="currentTab === 'question'" :region="region"></QuestionAdmin>

  </div>

</template>

<script>
import axios from "axios";
import QuestionAdmin from "@/components/QuestionAdmin";

const base_url = "https://foodrally-api.onrender.com"

export default {
  name: "Admin",
  components: {
    QuestionAdmin
  },

  data() {
    return {
      continentItems: [
          'Europe'
      ],
      regions: [],
      region: "",
      currentTab: 'question'
    }
  },
  methods: {

    loadRegionData(region){
      axios
          .get(
              `${base_url}/api/region/questions/${region}`
          )
          .then(async (response) => {
            this.questions = response.data;
            this.startCountDown();
          });
    }
  },
  created() {


    axios.get(`${base_url}/api/regions`)
        .then(response => {
          this.regions = response.data
          console.log(response)
        })

  }
}
</script>

<style scoped>

</style>