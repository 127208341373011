/*
	Author: Alexander Stanchula
	Desc  :  VueJS Register Component
*/

<template>
  <v-layout align-center justify-center >
    <v-flex min-width=150px >
      <v-card class="elevation-12">
        <v-toolbar dark color="green">
          <v-toolbar-title>Registrieren</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
            prepend-icon="mdi-account"
              label="Nutzername"
              v-model="username"
              id="username"
              type="text"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-shield"
              label="Passwort"
              v-model="password1"
              id="password"
              type="password"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-shield"
              label="Wiederhole dein Passwort"
              v-model="password2"
              id="repeat_password"
              type="password"
            ></v-text-field>
          </v-form>
          <div id="error_msg" v-if="signInError" style="color:red">
              {{signInErrorText}}
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="blue" @click="open">
                Zurück zum LogIn
            </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="startSignUp" 
          :loading="loading"
           dark color="blue">Jetzt Registrieren</v-btn>
        </v-card-actions>
        
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data: ()=>{
    return{
      username: "",
      password1: "",
      password2: "",
      loading: false,
      signInError: false,
      signInErrorText: "",
    }
  },
    methods: {
      ...mapActions([
        'doSignUp',
        'doLogin'
      ]),
        open: function(){
            this.$emit('openLogin')
        },
        startSignUp(){
          console.log('SIGNIN')
          if(this.password1 == this.password2){
            this.loading = true
            this.doSignUp({
              username: this.username,
              password: this.password1
            })
            this.SignUpLoop()
            
        // setTimeout(() => (this.logIn()), 2000)
        
          } else{
            this.signInError = true
            this.signInErrorText = 'Passwörter stimmen nicht überein!'
            this.loading = false

          }
          
        },
        SignUpLoop(){
          console.log('loop');
          if(this.signingUp){
            console.log(this.signingUp);
            setTimeout(() => (this.SignUpLoop()), 200)
          }
            
          else this.checkSignUp()
      },

       checkSignUp: function(){
         console.log('check', this.signUp);
        if(this.signUp == false){
          console.log('signup false');
              this.signInError = true
              this.signInErrorText = "Dieser Nutzername ist leider schon vergeben..."
              this.loading = false
            }else{
              console.log('signup true');
              this.logIn()
              // this.routeToApp()
            }
      },
      logInLoop(){
        if(this.loggingIn)
          setTimeout(() => (this.logInLoop()), 200)
        else this.routeToApp()
      },

      async logIn(){
        console.log('LOGGING IN');
        this.doLogin({
          username: this.username,
          password: this.password1
        })
        this.logInLoop()
        // setTimeout(() => (this.routeToApp()), 1000)

        
      },
      routeToApp(){
        this.loading = false

        console.log('route', this.accessToken);
        if(this.accessToken){
          this.$router.push('/app')
        }
      }
    },
    computed: {
      ...mapState([
        'loggingIn',
        'loginError',
        'accessToken',
        'signUp',
        'signingUp'
      ])
    },
}
</script>