/*
	Author: Alexander Stanchula
	Desc  :  Application layer with navigation bar
*/

<template>
  <v-app>
    <!-- Start of Navigation -->
    <nav>
      <!-- Start of app toolbar -->
      <v-toolbar >
        <v-toolbar-title class="headline text-uppercase"
          >FoodRalley</v-toolbar-title
        >
     
        <v-spacer></v-spacer>

        <v-toolbar-items  v-if="!accessToken" class="hidden-sm-and-down">
          <v-btn text to="/" exact>Home</v-btn>
          <v-btn text to="/about">About</v-btn>
        </v-toolbar-items>

        <v-toolbar-items v-else class="hidden-sm-and-down">
          <v-btn @click="doLogout" text to="/">Log Out</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!-- End of app toolbar -->
    </nav>

    <v-main>
      <!-- Display view pages here based on route -->
      <router-view @is_home="isHome = true" @is_not_home="isHome = false"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions} from 'vuex';

export default {
  name: "App",
    methods:{

    ...mapActions([
        'doLogout',
        'fetchAccessToken'
      ]),
      show_europe: function(){
        this.viewBox =  "900 -300 1100 2000"
      },
      show_world: function(){
        this.viewBox = "-125 -300 3100 2000"
      }
    },
    data(){
      return{
        isHome: true
      }
    },
    created(){
      this.fetchAccessToken()
    },
    computed: {
      ...mapState([
        'accessToken'
      ])
    },
}
</script>