<template>
  <v-overlay transparent :value="isVisible">
    <v-card class="mx-auto" light max-width="600">
      <v-img
          margin="20px"
          class="black--text align-end"
          height="300px"
          width="600px"
          src="@/assets/north_europe.jpeg"
      >
        <v-card-title>{{data.rTitle}}</v-card-title>
      </v-img>
      <v-card-subtitle class="pb-0"> Lebensmittel-Quiz</v-card-subtitle>
      <v-card-text class="text--primary">
        <div>{{ data.rText }}</div>
      </v-card-text>
      <v-card-actions bottom>
        <v-btn
            color="deep-purple lighten-2"
            text
            bottom
            @click="isVisible = false"
        >
          Zurück
        </v-btn>
        <v-btn
            color="deep-purple lighten-2"
            text bottom
            @click="show_questions"
        > Zum Quiz!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "RegionOverview",
  props: [
      "data",
      "rId"
  ],
  methods:{
    ...mapMutations([
      'updateCurrentComponent',
        'updateRegion',
        'updateRegionId'
    ]),
    show_questions: function () {
      this.updateRegion(this.data.rTitle)
      this.updateRegionId(this.rId)
      this.updateCurrentComponent('questionScreen')
    },
    test(){
      console.log(this.data)
    }
  },
  computed:{
    ...mapState([
      'CurrentComponent'
    ])
  },
  data(){
    return {
      isVisible: true
    }
  }
}
</script>

<style scoped>

</style>