/*
	Author: Alexander Stanchula
	Desc  :  VueJS Recipes Component
*/

<template >
  <div>
    <v-card light color="grey lighten-3" width="700px" v-if="showRecipes">
      <v-card-title> Rezepte </v-card-title>

      <v-card-text class="no_data" v-if="items.length == 0 && recipesLoading == false"
        >Sie haben leider noch keine Rezepte freigeschaltet...</v-card-text
      >
      <v-progress-circular
      style="width: 70px;
height: 70px;
position: absolute;
top: 50%;
left: 50%;
margin: -35px 0 0 -35px"
      v-else-if="recipesLoading == true"
      :width="3"
      color="grey"
      indeterminate
    ></v-progress-circular>

      <v-virtual-scroll bench="2" :items="items" item-height="200" height="600">
        <template v-slot:default="{ item }">
          <v-card
            :id="item.region"
            @click="fullViewRecipe"
            ripple="true"
            :key="item"
            elevation="3"
            class="mx-auto"
            max-width="690px"
          >
            <v-img
              src="../assets/koettbullar-rezept.jpeg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="190px"
            >
              <v-card-title
                style="font-size: 2em"
                v-text="item.region"
              ></v-card-title>
            </v-img>
          </v-card>
        </template>
      </v-virtual-scroll>
    </v-card>

    <v-card light color="grey lighten-3" width="700px" height="700" v-else>
      <v-card-title>{{ recipes[0].title }}</v-card-title>
      <v-img
        src="../assets/koettbullar-rezept.jpeg"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="190px"
      >
      </v-img>

      <v-responsive class="overflow-y-auto" height="400">
        <v-card>
          <v-card-title>Gut zu wissen!</v-card-title>
          <v-card-text>
            Was ist Köttbullar? Genauer gesagt, steht „Köttbullar“ für den
            Plural (Köttbulle wäre der Singular), denn es handelt sich um
            mehrere kleine Hackbällchen, die aus ähnlichen Zutaten wie eine
            klassische Frikadelle hergestellt werden.
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-card>
          <v-card-title>Zutaten</v-card-title>
          <v-list-item
            style="height: 10px"
            v-for="util in recipes[0].utils"
            :key="util"
          >
            <v-checkbox v-model="checkbox" :label="util"> </v-checkbox>
          </v-list-item>
        </v-card>
        <v-divider></v-divider>
        <v-card>
          <v-row
            v-for="step in recipes[0].steps"
            :key="step"
            style="pointer-events: none"
          >
            <v-col cols="2">
              <v-btn
                id="step_btn"
                top
                color="deep-purple lighten-2"
                elevation="4"
                fab
              >
                {{ step.index }}
              </v-btn>
            </v-col>
            <v-col cols="9">
              {{ step.text }}
            </v-col>
          </v-row>
        </v-card>
      </v-responsive>

      <v-card-actions>
        <v-btn color="deep-purple lighten-2" text @click="fullViewRecipe">
          Zurück
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn disabled color="deep-purple lighten-2" text>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M12,1L8,5H11V14H13V5H16M18,23H6C4.89,23 4,22.1 4,21V9C4,7.9 4.9,7 6,7H9V9H6V21H18V9H15V7H18C19.1,7 20,7.9 20,9V21C20,22.1 19.1,23 18,23Z"
            />
          </svg>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

const base_url = "https://foodrally-api.onrender.com"
export default {
  data: () => ({
    counter: 1,
    recipesLoading: false,
    showRecipes: true,
    recipes: [
      {
        title: "",
        info: `Was ist Köttbullar? Genauer gesagt, steht „Köttbullar“ für den Plural (Köttbulle wäre der Singular), 
                    denn es handelt sich um mehrere kleine Hackbällchen, 
                    die aus ähnlichen Zutaten wie eine klassische Frikadelle hergestellt werden.`,
        utils: [],
        steps: [
          {
            index: 1,
            text: "",
          },
          {
            index: 2,
            text: "",
          },
          {
            index: 3,
            text: "",
          },
        ],
      },
    ],
    regions: [],
  }),

  computed: {
    ...mapState(["PName"]),
    items() {
      var arr = [];
      this.regions.forEach((region) => {
        arr.push({
          region: region,
        });
      });
      return arr;
    },
  },

  methods: {
    fullViewRecipe() {
      this.showRecipes = !this.showRecipes;
    },
  },
  created() {
    this.recipesLoading = true
    let PName = localStorage.getItem("PName");
    axios
      .get(`${base_url}/api/getunlockedrecipes/${PName}`, {timeout: 5000})
      .then(async (response) => {
        console.log(response)
        let data = [response.data]
        var i = 0;
        data.forEach((recipe) => {
          this.recipes[i].title = recipe.RecTitle;
          this.regions.push(recipe.RecRegion)
          var utils = [];
          recipe.utils.forEach((util) => {
            utils.push(util.UText);
          });
          this.recipes[i].utils = utils;

          var steps = [];
          recipe.Steps.forEach((step) => {
            steps.push({
              index: step.StepNr,
              text: step.SText,
            });
          });
          this.recipes[i].steps = steps;
        });

        i++;
        this.recipesLoading = false
      })
      .catch((error) => {
        console.log(error);
        this.recipesLoading = false

      });
  },
};
</script>


<style scoped>
#recipesCard {
}
#step_btn {
  margin-left: 20px;
}
.no_data {
  font-size: 16px;
  text-align: center;
}
</style>