/*
	Author: Alexander Stanchula
	Desc  :  VueJS Home view - base for Login and Registration
*/

<template>
<v-row>
  <v-col style="border:none" cols="7">
    <v-img src="./../assets/logo.png">

    </v-img>
  </v-col>
 <v-col style="border:none">
   <v-container style="margin-top:100px">
    <Login @openRegister="login = false"  v-if="login" class="mt-3" />
    <Register @openLogin="login = true" v-else class="mt-3" />
  </v-container>
   </v-col> 
</v-row>
  
</template>

<script>
  import Login from "./../components/Login.vue";
  import Register from "./../components/Register.vue"

  export default {
    components: {
      Login,
      Register

    },
    data: () => ({
      massageForChild: "hallo",
      login : true,
    }),
  };
</script>