import Vue from 'vue'
import App from './App.vue'
import VueScrollStop from 'vue-scroll-stop'
import vuetify from './plugins/vuetify'
import router from './router'
import gsap from "gsap"

import store from './store.js'
// import store from './store';
import VeeValidate from 'vee-validate';

Vue.config.productionTip = false
Vue.use(VueScrollStop)
Vue.use(VeeValidate);


new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.mixin({
  created: function(){
    this.gsap = gsap
  }
})