<template>
  <div>
    <v-card
        v-for="(question, index) in questions" :key="question"
        elevation="2"
        outlined
        rounded
        class="rounded-xl"
        style="margin: 30px"
    >
      <v-card-text>
        <v-card-title>Question {{ index + 1 }}.</v-card-title>
        <v-text-field
            label="Topic"
            v-model="question.qTitle"
            outlined
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <v-text-field
            label="Question"
            v-model="question.qText"
            outlined
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <v-textarea
            label="Additional Information"
            outlined
            v-model="question.qInfo"
        >
        </v-textarea>
      </v-card-text>
      <v-card-subtitle>Answers:</v-card-subtitle>
      <v-card-text>
        <v-chip
            v-for="answer in question.Answers"
            :key="answer"
            x-large
            style="margin: 10px; width: 300px"
            :color="getChipColor(answer)"
        >
          <v-text-field
              style="margin: 10px; width: 250px"
              v-model="answer.atext"
          />
          <v-checkbox v-model="answer.isRight"/>
        </v-chip>

        <v-btn
            fab
            dark
            @click="addNewAnswer(question)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

      </v-card-text>
    </v-card>

    <div
        class="text-center"
        style="margin: 10px"
    >

      <v-btn
          fab
          dark
          color="blue"
          @click="addNewQuestion"
          style="margin: 5px"

      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-btn
          fab
          dark
          color="orange"
          @click="saveQuestions"
          style="margin: 5px"
          :disabled="saveBtnDisabled"
      >
        <v-icon>mdi-content-save-all</v-icon>
      </v-btn>
    </div>

    <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        centered
        top
        rounded="pill"
        elevation="24"
        :color="snackbarColor"
    >
      {{ snackbarMsg }}

    </v-snackbar>
  </div>

</template>

<script>
import axios from "axios";

const base_url = "https://foodrally-api.onrender.com"

export default {
  name: "QuestionAdmin",
  props: [
    'region'
  ],
  watch:{
    region: function (newVal, oldVal){
      console.log(`NEW REGION: ${newVal} from ${oldVal}`)
      this.loadQuestionData(newVal)
    }
  },
  data() {
    return {
      snackbar: false,
      snackbarMsg: "",
      snackbarColor: "",

      saveBtnDisabled: false,

      questions: [{
        "qText": "",
        "qInfo": "",
        "qTitle": "",
        "Answers": [
          {
            "atext": "",
            "isRight": true,
            "QuestionQId": null
          },
          {
            "atext": "",
            "isRight": false,
            "QuestionQId": null
          },
          {
            "atext": "",
            "isRight": false,
            "QuestionQId": null
          }
        ]
      }],
      regions: null,
      continent: null
    }
  },
  methods: {
    loadQuestionData(region) {
      axios
          .get(
              `${base_url}/api/region/questions/${region}`
          )
          .then(async (response) => {
            this.questions = response.data;
          });
    },
    getChipColor(answer) {
      if (answer.isRight)
        return "light-green"
      else return "red"
    },
    addNewQuestion() {
      this.questions.push({
        "qText": "",
        "qInfo": "",
        "rId": this.region,
        "qTitle": "",
        "Answers": [
          {
            "atext": "",
            "isRight": null,
            "QuestionQId": null
          },
          {
            "atext": "",
            "isRight": null,
            "QuestionQId": null
          },
          {
            "atext": "",
            "isRight": null,
            "QuestionQId": null
          }
        ]
      })
    },
    addNewAnswer(question) {
      question.Answers.push({
        "atext": "",
        "isRight": null,
        "QuestionQId": null
      })
    },
    saveQuestions() {
      console.log("SAVEQUESTIONS")
      console.log(this.questions)
      this.saveBtnDisabled = true;
      axios
          .post(`${base_url}/api/questions`, this.questions, {
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
          })
          .then(response => {
            console.log(response)
            if(!response){
              this.snackbarMsg = "Failed to save Questions..."
              this.snackbarColor = "red accent-2"
            } else {
              this.snackbarMsg = "Saved Questions successfully!"
              this.snackbarColor = "success"

            }
            this.snackbar = true;
            this.loadQuestionData(this.region)
            this.saveBtnDisabled = false;
          })
    },
  },
  created() {
    this.loadQuestionData(this.region)
  }
}
</script>

<style scoped>

</style>