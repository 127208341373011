/*
	Author: Alexander Stanchula
	Desc  :  VueJS Routes
*/

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './../views/Home.vue'
import App from './../views/InGame.vue'
import Questions from './../components/Questions.vue'
import Recipes from './../components/Recipes.vue'
import Settings from "@/components/Settings";



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },  {
    path: '/app',
    name: 'App',
    component: App
  },{
    path: '/questions',
    name: 'Questions',
    component: Questions
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  }
]

const router = new VueRouter({
  routes
})

export default router
